<template>
  <vue-final-modal @before-open="getProperties" v-slot="{ close }" v-bind="$attrs" classes="modal-container"
    content-class="modal-content" :esc-to-close="true" :click-to-close="true" :styles="{
      width:'100%',
      margin: '0 auto'
    }" :content-style="{
      width: 'auto',
      background: '#fff', 
      marginTop: '25px' 
    }">
    <p class="is-flex bg-gradient-to-r from-slate-50 to-slate-300">
      <span class="subtitle m-2">{{ properties.title }}</span>
      <button  @click="close" class="delete ml-auto m-3"></button>
      <!-- <button class="modal__close ml-auto mr-2" @click="close">
        <i class="fas fa-times"></i>
      </button> -->
    </p>
    <div class="modal__content p-3">
      <div class="has-background-white mt-1" v-if="!properties.empty">
        <krudh 
          :fields="properties.fields" 
          :columns="properties.columns" 
          :entity="properties.entity"
          :where="properties.where"
          :defaults="properties.defaults"
          :sort="properties.sort"
          :allow_delete="properties?.allow_delete"
          :joins="properties.joins"
          :join_select="properties.join_select"
          @close="close"
          >
        </krudh>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import krudh from '@/components/krudh/'

export default {
  setup() {
    return {}
  },
  data() {
    return {
      properties: { empty: true }
    }
  },
  methods: {
    getProperties(event) {
      this.properties = event.ref.params
    }
  },
  components: {
    VueFinalModal,
    krudh
  },
}
</script>

<style>
.modal__title {
  margin: 1.6rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal-content{
  width:80%!important;
}
</style>
